/* ------------------------------------------------------------------------------
 *  # Custom CSS code
 * ---------------------------------------------------------------------------- */
/*
  Global
*/
body {
    margin: 0;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5385;
    color: #333;
    text-align: left;
}
.btn-labeled-left{ padding-left: 3rem; }
.btn-labeled-left.btn-sm{ padding-left: 2.6rem; }
.badge{ text-transform: uppercase; }

.list-inline-dotted .list-inline-item:not(:last-child)::after{ margin-left: .3rem; opacity: 1}
.list-inline-dotted .list-inline-item:not(:last-child){ margin-right: .15rem !important; }

.bg-cta{ background-image: url(../global/backgrounds/panel_bg.png); }
.daterangepicker.dropdown-menu{z-index: 10000;}
.nav-pills-bordered .nav-link.active{ background-color:#69C5D1 }
.nav-pills-toolbar .nav-link{ background-color:#fff }
select.form-control{ background-color: #fff;width: inherit;}
.nav-sidebar .nav-item:not(.nav-item-header):last-child{ padding-bottom: 0;}

/*
  Colours
*/
.bg-pps-light-blue{ background-color:#047dc4; color: #fff;font-size: 12px; }
.bg-pps-light-grey{ background-color:#eee; color: #000 !important; }
.bg-pps-blue{ background-color:#047dc4; color: #fff; }
.bg-pps-gold{ background-color:#000000; color: #fff; }
.text-pps-light-blue, .text-pps-light-blue:hover{ color:#69C5D1;  }
.text-pps-blue, .text-pps-blue:hover{ color:#047dc4; }
.text-pps-gold, .text-pps-gold:hover{ color:#000000;  }
.border-left-pps-light-blue{ border-left-color: #69C5D1; }

/*
  Search tokens
*/
.token.bg-primary{ background-color:#000000 !important; line-height: 14px; margin-right: .3rem}
.tokenfield{ float: left; width: auto;}
.tokenfield .token .token-label{ font-size: 11px; padding: .2rem 1.4rem .250rem .6rem}
.tokenfield .token > .close{ right: .5rem;}
.token-input{ display:none }
.modal .token.bg-primary{ background-color:#69C5D1 !important }

/*
  Modal
*/
.modal{ z-index: 10000 }
.modal-title i{ font-size: 24px}
.modal .modal-body .media-title{ line-height: 20px;}
.modal .modal-body textarea{ height: 100px}
.modal .modal-body h6 a{ color: #000}
.modal .modal-body h6 a:hover{ text-decoration: underline;}
/*
  FAB Button
*/
.fab-menu-inner div[data-fab-label]::after{ text-transform: uppercase; font-size:12px; right: 3.5rem; background-color:#eee; color: #047dc4; box-shadow: 0 0px 0px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.15);}
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(1){ top:3.5rem }
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(2){ top:6.5rem }
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(3){ top:9.5rem }
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(4){ top:12.5rem }
.fab-menu-btn{ background-color:transparent; box-shadow: none; border: 1px solid #ddd; background-image: url(../../assets/images/pro-fit-circle.png);background-size: 60px;background-repeat: no-repeat;background-position: center -5px;}
.fab-menu-btn:hover,.fab-menu-btn:focus, .fab-menu[data-fab-state="open"] .fab-menu-btn{ box-shadow: 0px 0px 5px rgba(0,0,0,.1),0px 0px 5px rgba(0,0,0,.1) !important }
.fab-menu-inner > li .btn:hover{ background-color: #69C5D1}

/*
  Pagination
*/
.page-item.active .page-link{ background-color:#047dc4; }

/*
  Forms
*/
.form-control:not(.border-bottom-1):not(.border-bottom-2):not(.border-bottom-3):focus{ border-color:#69C5D1; }
.form-control:focus{box-shadow: 0 0 0 0 transparent,0 1px 0 #69C5D1;}
.btn-ladda .ladda-progress{background-color: rgba(255,255,255,.1);}
.uniform-checker span, .uniform-choice span{border: .125rem solid #333;color: #333;}
.breadcrumb i{ font-size: 16px}
.breadcrumb-item + .breadcrumb-item::before{ display: none }

/*
  Header
*/
.page-title{ padding:1rem 0;}
.page-title h1{ color: #333; font-size: 2rem;font-size: 2rem;padding: 0 10px;}
.page-title h1 i{ font-size: 2rem; }
.card-header.bg-transparent.header-elements-inline{ color: #000 }
.navbar-brand a{ position: relative;}
.navbar-brand img{ height: 38px; width: auto;}
.navbar .navbar-title{ margin-top: 12px; color: #fff;}
.navbar-static{ z-index: 10000 }
.sidebar-user-material-body .card-body{ line-height: 14px}
.sidebar-user-material-body .card-body img{border: 1px solid #fff;}
.sidebar-user-material-body a{ color: #000}
.sidebar-user-material-body a:hover{ text-decoration: underline;}
.dropdown-content-footer{ background-color: #eee !important}
.navbar-nav .dropdown-content-body .media-body a.media-title{color: #000;}
.navbar-nav .dropdown-content-body .media-body a.media-title:hover{  text-decoration: underline; }
#user-nav{ border-bottom: none}
.page-header-content{ background-color: #eee;}
.dropdown-content-body .media-body .media-title a{ color: #000 }
.dropdown-content-body .media-body .media-title a:hover{ color: #000; text-decoration: underline; }
.nav-item.dropdown .fa-comment-dots{ font-size: 20px}
/*
  Footer
*/
#navbar-footer .navbar-nav-link{ padding-left: .3rem; padding-right: .3rem}
#navbar-footer .navbar-text a{ color: #0a6ebd }
#navbar-footer .navbar-text a:hover{ text-decoration: underline;}

/*
  Sidear
*/
.sidebar-user-material .sidebar-user-material-body{ background-image: url(../global/backgrounds/panel_bg.png); background-color: #eee;}
.sidebar-user-material-footer > a{ color:#ffffff; }
.sidebar-content .media-body .media-title a{ color: #0a6ebd !important }
.sidebar-content .media-body .text-muted a{ color: #999 !important }
.sidebar-content .media-body .text-muted a:hover{ text-decoration: underline;}

/*
  Featured Post
*/
.featured-post-tag{ box-shadow: 0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24); text-transform: uppercase; font-weight: 500;font-size: 12px; -webkit-border-top-left-radius: 3px; -webkit-border-top-right-radius: .125rem; -moz-border-radius-topleft: .125rem; -moz-border-radius-topright: .125rem; border-top-left-radius: .125rem; border-top-right-radius: .125rem;}
.featured-post .list-inline-item:not(:last-child){ margin-right: .5rem; }
.featured-post .card-body .card-footer{ border: 0}
/*
  Jobs
*/
#jobs .media-body .media-title a{ color: #000;font-size: 18px; }
#jobs .media-body .media-title a:hover,
#jobs .media-body .list-inline-item a:hover{ color: #000; text-decoration: underline; }
#jobs code{ color: #000000}

/*
  Mentors
*/
#mentors .media-body .media-title a{ color: #000;font-weight: 500; font-size: 18px; }
#mentors .media-body .media-title a:hover,
#mentors .media-body .list-inline-item a:hover{ color: #000; text-decoration: underline; }
#mentors code{ color: #000000}

/*
  Work Readiness
*/
#work-readiness .card-body h5 a{ color: #000; line-height: 24px }
#work-readiness .card-body h5 a:hover, #work-readiness .card-body li a.text-muted:hover,  #work-readiness .article li a.text-muted:hover{ text-decoration: underline; }
.show > .btn-link.dropdown-toggle{ color: #69C5D1 }
#work-readiness .media-body .media-title a:hover,
#work-readiness .media-body .list-inline-item a:hover{ color: #000; text-decoration: underline; }

#work-readiness .article .thumb{ float: left; width: 250px; height:auto; margin: 0 10px 10px 0}
#work-readiness .article .media-body .media-title a{ color: #000}

#network .card h5 a,#network .card .media-title a{ color: #000}
#network .card h5 a:hover,#network .card a.text-muted:hover,#network .card .media-title a:hover{ text-decoration: underline }
#network .timeline::before,#network .timeline::after{ background-color: transparent}

/*
 Messages
*/
#messages .card-body{ padding: 0}
#messages .card-body a{ color: #000}
#messages .card-body .media{ border-top: 1px solid #ddd; padding: 1.25rem}
#messages .card-body .input-group{ padding: 1.25rem}
#messages .card-body .media:first-child{ border-bottom: none}
#messages .media.read{ font-weight: normal;}
#messages .media.unread{ font-weight: bold; }
#messages .replies{background-color: #eee}
#messages .replies-header{ background-color: #ddd; font-size: 10px}
#messages .card-body a.btn{ color: #fff;}

/*
	MOBILE
*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {

}

/* Medium Devices, Desktops : col-md */
@media only screen and (max-width : 992px) {

}


/* Small Devices, Tablets : col-sm */
@media only screen and (max-width : 768px) {

}

/* col-ms */
@media only screen and (max-width: 767px) {

}

/* Extra Small Devices, Phones : col-xs */
@media only screen and (max-width : 480px) {
.media{ display: block; margin-bottom:10px }
.featured-post .media .align-self-center .btn,.card-body .btn.bg-pps-blue{ margin-top:10px; font-size: 10px}

.media .ml-3{ margin-left: 0 !important}
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}

.new-post-editor {
    min-height: 150px;
}
